import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useMode } from "../theme";
import axios from "axios";
import {
    Box,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { tokens } from "../theme";
import LineChart from "../components/LineChart";
import BarChart from "../components/BarChart";
import PieChart from "../components/PieChart";
import GeographyChart from "../components/GeographyChart";
import StatBox from "../components/StartBox";
import { ResponsivePie, Pie } from "@nivo/pie";
import { API_URL } from "../utils";

function Action() {
    const [data, setData] = useState({
        primary_market_nft_buy_unit: 0,
        secondary_market_nft_buy_unit: 0,
        primary_market_nft_sell_unit: 0,
        secondary_market_nft_sell_unit: 0,
    });

    const [transactions, setTransactions] = useState([]);
    const [revenueData, setRevenueData] = useState([]);
    const { org_id, org_name, org_country } = useSelector(state => state.auth);
    const [theme, colorMode] = useMode();
    const colors = tokens(theme.palette.mode);
    const isXlDevices = useMediaQuery("(min-width: 1200px)");
    const isMdDevices = useMediaQuery("(min-width: 992px)");
    const isXsDevices = useMediaQuery("(max-width: 576px)");
    const marketTypeMap = {
        primary_market: 'Primary Market',
        secondary_market: 'Secondary Market'
    };

    useEffect(() => {
        console.log("ORG ID IS ", org_id)
        console.log("ORG NAME IS ", org_name)
        console.log("ORG Country IS ", org_country)
        fetchData();
        fetchTransactions();
    }, [org_id]);

    const fetchData = async () => {
        try {
            const res = await axios.get(
                `${API_URL}/organisations/dashboard/data/${org_id}/nft-history/`
            );
            setData(res.data);
        } catch (error) {
            console.error("Error fetching data", error);
        }
    };

    const fetchTransactions = async () => {
        try {
            const res = await axios.get(
                `${API_URL}/nfts/transactions-history/`
            );
            setTransactions(res.data);
        } catch (error) {
            console.error("Error fetching transactions", error);
            setTransactions([]); // Set default value on error
        }
    };

    const piedata = [
        { id: 'Primary Buy', value: data.primary_market_nft_buy_total },
        { id: 'Secondary Buy', value: data.secondary_market_nft_buy_total },
        { id: 'Primary Sell', value: data.primary_market_nft_sell_total },
        { id: 'Secondary Sell', value: data.secondary_market_nft_sell_total },
    ];

    const avaiableData = piedata.filter(item => item.value > 0)

    return (
        <Box sx={{
            mt: { xs: '15px', md: '24px' },
            ml: { xs: '10px', md: '20px' },
            mr: { xs: '10px', md: '20px' }
        }} >
            <div
            >
                <div className="row">
                    <div className="col-12 col-md-6 col-xl-3 mb-4">
                        <div style={{ height: '140px', backgroundColor: '#E3BC9A21' }} className="d-flex justify-centent-center align-items-center">
                            <Box
                                width="100%"
                                padding="25px 0px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <StatBox
                                    title={data.primary_market_nft_buy_unit}
                                    subtitle="Total NFTs bought: Primary Market"
                                    subtitleColor="black"
                                />
                            </Box>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3 mb-4">
                        <div style={{ height: '140px', backgroundColor: '#E3BC9A21' }} className="d-flex justify-centent-center align-items-center">
                            <Box
                                width="100%"
                                padding="25px 0px"

                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <StatBox
                                    title={data.secondary_market_nft_buy_unit}
                                    subtitle="Total NFTs bought: Secondary Market"
                                    subtitleColor="black"
                                />
                            </Box>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3 mb-4">
                        <div style={{ height: '140px', backgroundColor: '#E3BC9A21' }} className="d-flex justify-centent-center align-items-center">
                            <Box
                                width="100%"
                                padding="25px 0px"

                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <StatBox
                                    title={data.primary_market_nft_sell_unit}
                                    subtitle="Total NFTs sold: Primary Market"
                                    subtitleColor="black"
                                />
                            </Box>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-3 mb-4">
                        <div style={{ height: '140px', backgroundColor: '#E3BC9A21' }} className="d-flex justify-centent-center align-items-center">
                            <Box
                                width="100%"
                                padding="25px 0px"

                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <StatBox
                                    title={data.secondary_market_nft_sell_unit}
                                    subtitle="Total NFTs sold: Secondary Market"
                                    subtitleColor="black"
                                />
                            </Box>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* Line Chart */}
                    <div className="col-12 col-xl-8 mb-4">
                        <Box
                            bgcolor='#E3BC9A21'
                        >
                            <Box

                                p="15px"
                                display="flex"
                                justifyContent="space-center"
                            >
                                <Box>
                                    <Typography
                                        variant="h6"
                                        fontWeight="600"
                                        color={colors.black}
                                    >
                                        Carbon Credits vs Price
                                    </Typography>
                                </Box>
                            </Box>
                            <Box height="330px" >
                                <LineChart isDashboard={true} setRevenueData={setRevenueData} />
                            </Box>
                        </Box>
                    </div>
                    <div className="col-12 col-xl-4 mb-4 d-none d-xl-block">
                        {/* ---------------RECENT transactions ------------------ */}
                        <Box
                            bgcolor='#E3BC9A21'
                        >
                            <Box borderBottom={`4px solid ${colors.black}`} p="15px">
                                <Typography color={colors.black} variant="h6" fontWeight="600">
                                    Recent transactions
                                </Typography>
                            </Box>
                            <div style={{
                                height: '330px'
                            }}>


                                <div style={{
                                    marginBottom: '12px',
                                    overflow: "auto",
                                    height: '318px'
                                }}>

                                    {(transactions && transactions.length > 0) ? (
                                        transactions.map((transaction, index) => (
                                            <Box

                                                key={`${transaction.nft_id}-${index}`}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                borderBottom={`1px solid ${colors.primary[500]}`}
                                                p="15px"
                                                sx={{ gap: "15px" }}
                                            >
                                                <Box>
                                                    <Typography
                                                        color="#8B4513"
                                                        variant="h7"
                                                        fontWeight="600"

                                                    >
                                                        {transaction.nft_id}
                                                    </Typography>
                                                    <Typography color={colors.black}>
                                                        {transaction.org_name || ""}
                                                    </Typography>
                                                </Box>
                                                <Typography sx={{ textWrap: 'nowrap', marginBottom: '0px !important' }} color={colors.black}>
                                                    {transaction.carbon_credits} tCO2eq
                                                </Typography>
                                                <Box

                                                    bgcolor='#E3BC9A21'
                                                    p="5px 10px"
                                                    borderRadius="5px"
                                                >
                                                    {marketTypeMap[transaction.market_type] || transaction.market_type}
                                                </Box>
                                            </Box>

                                        ))
                                    ) : (
                                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                            <Typography color={colors.black}>
                                                No transactions found.
                                            </Typography>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Box>
                    </div>
                </div>
                <div className="row">

                    <div className="col-12 col-lg-6 mb-4 d-block d-xl-none">
                        {/* ---------------RECENT transactions ------------------ */}
                        <Box
                            bgcolor='#E3BC9A21'
                        >
                            <Box borderBottom={`4px solid ${colors.black}`} p="15px">
                                <Typography color={colors.black} variant="h6" fontWeight="600">
                                    Recent transactions
                                </Typography>
                            </Box>
                            <div style={{
                                height: '280px'
                            }}>
                                <div style={{
                                    marginBottom: '12px',
                                    overflow: "auto",
                                    height: '268px'
                                }}>

                                    {(transactions && transactions.length > 0) ? (
                                        transactions.map((transaction, index) => (
                                            <Box

                                                key={`${transaction.nft_id}-${index}`}
                                                display="flex"
                                                alignItems="center"
                                                justifyContent="space-between"
                                                borderBottom={`1px solid ${colors.primary[500]}`}
                                                p="15px"
                                                sx={{ gap: "15px" }}
                                            >
                                                <Box>
                                                    <Typography
                                                        color="#8B4513"
                                                        variant="h7"
                                                        fontWeight="600"

                                                    >
                                                        {transaction.nft_id}
                                                    </Typography>
                                                    <Typography color={colors.black}>
                                                        {transaction.org_name || ""}
                                                    </Typography>
                                                </Box>
                                                <Typography sx={{ textWrap: 'nowrap', marginBottom: '0px !important' }} color={colors.black}>
                                                    {transaction.carbon_credits} tCO2eq
                                                </Typography>
                                                <Box

                                                    bgcolor='#E3BC9A21'
                                                    p="5px 10px"
                                                    borderRadius="5px"
                                                >
                                                    {marketTypeMap[transaction.market_type] || transaction.market_type}
                                                </Box>
                                            </Box>
                                        ))
                                    ) : (
                                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                            <Typography color={colors.black}>
                                                No transactions found.
                                            </Typography>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Box>
                    </div>

                    {/* PIE Chart for Data Visualisation */}
                    <div id="action-dash" className="col-12 col-lg-6 col-xl-4 mb-4">
                        <Box
                            width="100%"
                            backgroundColor='#E3BC9A21'
                            p="15px"
                            display="flex"
                            flexDirection="column"
                            alignItems="start"
                            justifyContent="start" // Ensure content is centered
                        >
                            <Typography variant="h6" fontWeight="600" color={colors.black}>
                                Environmental Impact
                            </Typography>
                            <div id="environmental-impact" style={{ height: '230px', width: '100%' }}>
                                <Box
                                    mt="10px"
                                >
                                    {
                                        (avaiableData && avaiableData.length > 0) ? <PieChart data={avaiableData} />

                                            : <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                                <Typography color={colors.black}>
                                                    No Data Found
                                                </Typography>
                                            </div>
                                    }
                                </Box>
                            </div>
                        </Box>
                    </div>


                    <div className="col-12 col-lg-6 col-xl-4 mb-4">
                        {/* Bar Chart */}
                        <Box
                            gridColumn={isXlDevices ? "span 4" : "span 3"}
                            gridRow="span 2"
                            backgroundColor='#E3BC9A21'
                            width="100%"
                        >
                            <Typography
                                variant="h6"
                                fontWeight="600"
                                sx={{ p: "15px" }}
                            >
                                Revenue Generated
                            </Typography>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                height="250px"
                                mt="-20px"
                            >
                                <BarChart isDashboard={true} />
                            </Box>
                        </Box>
                    </div>
                    <div className="col-12 col-lg-6 col-xl-4 mb-4">
                        {/* Geography Chart */}
                        <Box
                            gridColumn={isXlDevices ? "span 4" : "span 3"}
                            gridRow="span 2"
                            backgroundColor='#E3BC9A21'
                            padding="15px"
                            width="100%"
                        >
                            <Typography variant="h6" fontWeight="600" mb="15px">
                                Geographical Distribution
                            </Typography>
                            <Box
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                height="215px"
                            >
                                <GeographyChart isDashboard={true} />
                            </Box>
                        </Box>
                    </div>
                </div>
            </div>
        </Box>
    );
}

export default Action;