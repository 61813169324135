import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Paper, Typography, Grid, Button } from "@mui/material";
import { Input, Label, FormGroup, Spinner } from "reactstrap";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { useAtom } from "jotai";
import { loadStdlib } from "@reach-sh/stdlib";
import { PeraWalletConnect } from "@perawallet/connect";

import {
  BNtoN,
  contract_Abstraction,
  secondary_contract_Abstraction,
} from "../../utils/functions";
import {
  halt,
  halt_secondary,
  stopContract,
  stopContract_secondary,
} from "../../utils/functions/interact";
import { accountsAtom, walletAtom } from "../../utils/wallet";
import { API_URL, CONFIG } from "../../utils";
import "./my-nft-item.css";
import { indexer, reach } from "../../utils/functions/arc69.ts";
import { networkConfig } from "../../config/network";
import MakePeraConnect from "../../utils/helper";
import { connectWallet } from "../Navbar/utils";

const MyNFTItem = ({ nft, nfts }) => {
  const state = nft.state;
  const [componentState, setComponentState] = useState(
    nft?.detail?.detail_state
  );
  const navigate = useNavigate();
  const [wallet, setWallet] = useAtom(walletAtom);
  const [accounts, setAccounts] = useAtom(accountsAtom);

  const [formData, setFormData] = useState({
    price: 0,
    quantity: nft.available_units,
  });
  const [toggleForm, setToggleForm] = useState(false);
  const [btnClicked, setBtnClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(null);

  const [views, setViews] = useState({});
  useEffect(() => {
    let isMounted = true;
    const fetchData = async () => {
      const _indexer = await indexer();
      const reach = loadStdlib("ALGO");
      reach.setWalletFallback(
        reach.walletFallback({
          providerEnv: networkConfig.network,
          WalletConnect: MakePeraConnect(PeraWalletConnect),
        })
      );

      const accountInfo = await _indexer.lookupAccountAssets(nft.address).do();
      const assets = accountInfo.assets;
      const info = assets?.find((asset) => {
        return asset?.["asset-id"] === nft.asset_id;
      });

      if (!wallet?.networkAccount?.addr) {
        await reach.getDefaultAccount().then((res) => setWallet((_) => res));
      }
      if (!wallet?.networkAccount?.addr) return;

      const meta = await wallet.tokenMetadata(nft.asset_id);
      const amt = info.amount;
      if (isMounted) setBalance(BNtoN(amt));
    };
    if (
      [
        "Issuance",
        "New",
        "Primary Market",
        "Halt Primary",
        "Halt Secondary",
      ]?.includes(nft.state)
    ) {
      //fetchData();
    }
    return () => {
      isMounted = false;
    };
  }, [nft.address, nft.asset_id, wallet?.networkAccount?.addr]);

  useEffect(() => {
    if (balance) {
      setFormData((prev) => ({ ...prev, quantity: +balance }));
    }
  }, [balance]);
  const getExtension = (filename) => {
    return filename?.split(".").pop();
  };
  let grand_id = nft.id;
  if (nft.grand_parent !== null) {
    grand_id = nft.grand_parent;
  }

  const putOnSalePrimary = async () => {
    console.log("nft:", nft);
    const token = {
      price: formData.price * 1000000,
      tokenId: nft?.asset_id,
      name: nft?.asset_name + "@arc69",
      symbol: nft?.id.toString(),
      decimals: parseInt(nft?.decimals),
      supply: formData?.quantity,
    };
    // ! Remove block later
    // const object = {
    //   user: localStorage?.ge tItem("user_id"),
    //   state: "Primary Market",
    //   price: formData.price * 1000000,
    //   contract_id: "1234567",
    //   supply: formData?.quantity,
    //   address: localStorage?.getItem("wallet-address"),
    // };
    // const body = JSON.stringify(object);

    // const res = await axios.patch(
    //   `${API_URL}/nfts/${nft.id}/primary/`,
    //   body,
    //   CONFIG
    // );
    // console.log({ res });
    // return;
    // ! Remove block later
   if (!wallet?.networkAccount?.addr){
    swal({
      title: "Missing Wallet!",
      text: `Please connect your wallet and try again`,
      icon: "info",
      button: false,
      timer: 1200,
    });
    return;
   }
    try {
      const response = await contract_Abstraction(wallet, token);
      console.log("res:", response);
      if (response.info) {
        const object = {
          user: localStorage.getItem("user_id"),
          state: "Primary Market",
          price: formData.price * 1000000,
          contract_id: response.info._hex,
          supply: formData?.quantity,
          address: localStorage.getItem("wallet-address"),
        };
        const body = JSON.stringify(object);

        const res = await axios.patch(
          `${API_URL}/nfts/${nft.id}/primary/`,
          body,
          CONFIG
        );
        if (res.status === 200) {
          swal({
            title: "Success!",
            text: `Successfully deployed contract`,
            icon: "success",
            button: false,
            timer: 1200,
          });
          setTimeout(() => {
            navigate(`/primary-market`);
          }, 500);
        }
      } else {
        swal({
          title: "Failed!",
          text: `The NFT is failed to put on Primary market`,
          icon: "error",
          button: false,
          timer: 1200,
        });
      }
    } catch (error) {
      console.log(error);
      console.log(error.message);
      swal({
        title: "Failed!",
        text: `${error.message}`,
        icon: "error",
        button: false,
        timer: 1200,
      });
    }
    setLoading(false);
  };

  const putOnSaleSecondary = () => {
    if (!wallet?.networkAccount?.addr) {
      return verifyWallet();
    }
    console.log("secondary_nft:", nft);
    const token = {
      name: nft?.asset_name + "@arc69",
      symbol: nft?.id.toString(),
      decimals: parseInt(nft?.decimals),
      supply: formData.quantity,
      price: formData.price * 1000000,
      tokenId: nft?.asset_id,
      Parent: nft?.contract_id,
    };
    console.log(wallet, token);
    if (!wallet?.networkAccount?.addr){
      swal({
        title: "Missing Wallet!",
        text: `Please connect your wallet and try again`,
        icon: "info",
        button: false,
        timer: 1200,
      });
      return;
     }
    secondary_contract_Abstraction(wallet, token).then((response) => {
      console.log("res:", response);
      if (response.info) {
        const object = {
          user: localStorage.getItem("user_id"),
          state: "Secondary Market",
          price: formData.price * 1000000,
          contract_id: response.info._hex,
          occupied: formData.quantity,
          address: localStorage.getItem("wallet-address"),
          nft_id: nft?.nft_id,
        };
        const body = JSON.stringify(object);

        axios
          .patch(`${API_URL}/nfts/${nft.id}/secondary/`, body, CONFIG)
          .then((res) => {
            if (res.status === 200) {
              swal({
                title: "Success!",
                text: `Successfully deployed contract`,
                icon: "success",
                button: false,
                timer: 1200,
              });
              setTimeout(() => {
                navigate(`/secondary-market`);
              }, 500);
            }
          })
          .catch((error) => {
            console.log(error.message);
            swal({
              title: "Failed!",
              text: `${error.message}`,
              icon: "error",
              button: false,
              timer: 1200,
            });
          });
      } else {
        swal({
          title: "Failed!",
          text: `The NFT is failed to put on Secondary market`,
          icon: "error",
          button: false,
          timer: 1200,
        });
      }
    });
    setLoading(false);
  };

  const putOnSale = () => {
    if (!wallet?.networkAccount?.addr) {
      return verifyWallet();
    }
    console.log("state:", nft.state);
    setLoading(true);
    setBtnClicked(true);
    // check if the input is > 0
    const inputNum = Number(formData.price)
    if (!Number.isInteger(inputNum) || formData.price < 1) {
      swal({
        title: "Wrong Input Format!",
        text: `Price of one unit should be a positive integer`,
        icon: "info",
        button: false,
        timer: 1200,
      });
      setLoading(false);
      return;
    }
    if (Object.keys(wallet).length === 0) {
      swal({
        title: "Missing Wallet!",
        text: `Please connect your wallet and try again`,
        icon: "info",
        button: false,
        timer: 1200,
      });
      setLoading(false);
      return;
    }

    if (nft?.detail?.state == "Issuance") {
      putOnSalePrimary();
    }
    // if (nft?.detail?.state === "Primary Market")
    else {
      putOnSaleSecondary();
    }
    setBtnClicked(false);
    setLoading(true);
    console.log("btnClicked aft: ", btnClicked);
  };
  console.log({ wallet });

  const verifyWallet = () => {
    console.log({ wallet: wallet });
    Swal.fire({
      title: "Missing Wallet!",
      text: `Please connect your wallet and try again`,
      icon: "error",
    });

    // throw Error("No wallet connected");
  };

  const haltNFT = async () => {
    if (!wallet?.networkAccount?.addr) {
      return verifyWallet();
    }
    if (nft?.detail?.state === "Primary Market") {
      const obj = {
        wallet: wallet,
        info: nft?.detail?.contract_id,
      };
      halt(obj)
        .then((response) => {
          if (response === `Successfully halted  tokens from sale`) {
            const object = {
              user: localStorage.getItem("user_id"),
              state: nft?.detail?.detail_state ?? "halted",
            };
            const body = JSON.stringify(object);

            axios
              .patch(`${API_URL}/nfts/${nft?.detail?.id}/halt/`, body, CONFIG)
              .then((res) => {
                if (res.status === 200) {
                  swal({
                    title: "Success!",
                    text: `Successfully halted  tokens from sale`,
                    icon: "success",
                    button: false,
                    timer: 1200,
                  });
                  setComponentState((prev) =>
                    prev == "halted" ? "active" : "halted"
                  );
                  setTimeout(() => {
                    navigate(`/my-nfts`);
                  }, 500);
                }
              })
              .catch((error) => {
                console.log(error.message);
                swal({
                  title: "Failed!",
                  text: `Failed to halt contract`,
                  icon: "error",
                  button: false,
                  timer: 1200,
                });
              });
          } else {
            swal({
              title: "Failed!",
              text: `Failed to halt contract`,
              icon: "error",
              button: false,
              timer: 1200,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          if (
            error?.message
              ?.toString()
              ?.includes("Request Rejected: The User has rejected the request.")
          ) {
            Swal.fire({
              title: "Failed!",
              text: `The User has rejected the request.`,
              icon: "error",
              button: false,
            });
          }
         else  {
            Swal.fire({
              title: "Failed!",
              text: `Unknown error`,
              icon: "error",
              button: false,
            });
          }
        });
    } else if (nft.state === "Secondary Market") {
      const obj = {
        wallet: wallet,
        info: nft?.detail?.contract_id,
      };

      halt_secondary(obj).then((response) => {
        if (response === `Successfully halted  tokens from sale`) {
          const object = {
            user: localStorage.getItem("user_id"),
            state: nft?.detail?.detail_state ?? "halted",
          };
          const body = JSON.stringify(object);

          axios
            .patch(`${API_URL}/nfts/${nft?.detail?.id}/halt/`, body, CONFIG)
            .then((res) => {
              if (res.status === 200) {
                swal({
                  title: "Success!",
                  text: `Successfully halted  tokens from sale`,
                  icon: "success",
                  button: false,
                  timer: 1200,
                });
                setComponentState((prev) =>
                  prev == "halted" ? "active" : "halted"
                );
                setTimeout(() => {
                  navigate(`/my-nfts`);
                }, 500);
              }
            })
            .catch((error) => {
              console.log(error.message);
              swal({
                title: "Failed!",
                text: `Failed to halt contract`,
                icon: "error",
                button: false,
                timer: 1200,
              });
            });
        } else {
          swal({
            title: "Failed!",
            text: `Failed to halt contract`,
            icon: "error",
            button: false,
            timer: 1200,
          });
        }
      });
    }
  };

  const stopNFT = async () => {
    if(componentState == "halted"){
      return 
    }
    if (!wallet?.networkAccount?.addr) {
      return verifyWallet();
    }
    console.log("state:", nft.state);
    if (nft.state === "Primary Market") {
      try {
        const response = await stopContract(wallet, nft.contract_id);
        console.log("response:", response);
        if (response === "Successfully deleted contract") {
          const object = {
            user: localStorage.getItem("user_id"),
            state: nft?.detail?.detail_state ?? "stopped",
          };
          const body = JSON.stringify(object);

          const res = await axios.patch(
            `${API_URL}/nfts/${nft?.detail?.id}/update/`,
            body,
            CONFIG
          );
          if (res.status === 200) {
            swal({
              title: "Success!",
              text: `Successfully deleted contract`,
              icon: "success",
              button: false,
              timer: 1200,
            });
            setTimeout(() => {
              navigate(`/my-nfts`);
            }, 500);
          }
        } else {
          swal({
            title: "Failed!",
            text: `Failed to delete contract`,
            icon: "error",
            button: false,
            timer: 1200,
          });
        }
      } catch (error) {
        swal({
          title: "Failed!",
          text: `Failed to delete contract: ${error.message}`,
          icon: "error",
          button: false,
          timer: 1200,
        });
      }
    } else if (nft.state === "Secondary Market") {
      stopContract_secondary(wallet, nft.contract_id).then((response) => {
        console.log("response:", response);
        if (response === "Successfully deleted contract") {
          const object = {
            user: localStorage.getItem("user_id"),
            state: nft?.detail?.detail_state ?? "stopped",
          };
          const body = JSON.stringify(object);

          axios
            .patch(`${API_URL}/nfts/${nft?.detail?.id}/update/`, body, CONFIG)
            .then((res) => {
              if (res.status === 200) {
                swal({
                  title: "Success!",
                  text: `Successfully deleted contract`,
                  icon: "success",
                  button: false,
                  timer: 1200,
                });
                setTimeout(() => {
                  navigate(`/my-nfts`);
                }, 500);
              }
            })
            .catch((error) => {
              console.log(error.message);
              swal({
                title: "Failed!",
                text: `Failed to delete contract`,
                icon: "error",
                button: false,
                timer: 1200,
              });
            });
        } else {
          swal({
            title: "Failed!",
            text: `Failed to delete contract`,
            icon: "error",
            button: false,
            timer: 1200,
          });
        }
      });
    }
  };
  const unit_calculate = (n) => {
    if (n.grand_parent) {
      const temp_nft = nfts.find((nt) => nt.id === n.grand_parent);
      if (temp_nft) {
        return temp_nft.carbon_credits / temp_nft.total;
      } else {
        const temp_parent_nft = nfts.find((nt) => nt.id === n.parent);
        if (temp_parent_nft) {
          return temp_parent_nft.carbon_credits / temp_parent_nft.total;
        } else {
          return n.carbon_credits / n.total;
        }
      }
    } else {
      return n.carbon_credits / n.total;
    }
  };
  return (
    <Grid item xs={12} sm={6} md={6} lg={4}>
      <Paper
        className="detail-page-link my-nft-item ml-0"
        sx={{ p: 2 }}
        style={{ boxShadow: "none", border: "1px solid #d9dbdd" }}
      >
        <p>{nft?.detail.state}</p>
        <span
          onClick={() => navigate(`/market-history/${nft.id}/`)}
          sx={{ cursor: "pointer" }}
        >
          {getExtension(nft.file)?.toLowerCase() === "pdf" && (
            <iframe src={nft.asset_url} width="100%" height="220px"></iframe>
          )}
          {getExtension(nft.file)?.toLowerCase() !== "pdf" && (
            <img
              src={nft.asset_url}
              alt="Thumbnail"
              height={220}
              width="100%"
              className="my-1"
            />
          )}
        </span>
        <Typography sx={{ marginTop: "8px" }}>
          DCarbonX ID: <b>{nft.nft_id}</b>
        </Typography>
        <Typography>
          Issuer of Fractionalised NFT: <b>{nft.org_name}</b>
        </Typography>
        <Typography sx={{ my: 1 }}>
          Carbon Credits:{" "}
          <b>
            {nft.carbon_credits} {nft.unit_name}
          </b>
        </Typography>
        <Typography sx={{ my: 1 }}>
          Total Supply of Units: <b>{nft.total_supply_units}</b>
        </Typography>
        <Typography sx={{ fontSize: "16px", marginTop: "0px" }}>
          1 Unit:{" "}
          <strong>
            {/* {nft.total ? nft.carbon_credits / nft.total : ""} */}
            {unit_calculate(nft)} {nft.unit_name}
          </strong>
        </Typography>
        {["Primary Market", "Halt Primary", "Halt Secondary"].includes(
          nft.state
        ) ? (
          <div className="nft-item mb-3">
            Available Units:{" "}
            <span style={{ fontWeight: "bold" }}>{nft.available_units}</span>{" "}
          </div>
        ) : (
          <div className="nft-item mb-3">
            Available Units:{" "}
            <span style={{ fontWeight: "bold" }}>{nft.available_units}</span>{" "}
          </div>
        )}
        {nft?.detail?.amount < 1 && (
          <div className="d-flex">
            <Typography className="sold-out">OUT OF UNITS</Typography>
          </div>
        )}
        {[
          "Issuance",
          "New",
          // "Primary Market",
          // "Halt Primary",
          // "Halt Secondary",
          // "Secondary Market",
        ].includes(nft.state) &&
          nft?.detail?.amount > 0 && (
            <div className="nft-item">
              {!toggleForm && (
                <Button
                  className="info-btn mb-2"
                  onClick={() => setToggleForm(!toggleForm)}
                  variant="contained"
                >
                  Sell
                </Button>
              )}
              {toggleForm && (
                <div>
                  <FormGroup>
                    <Typography sx={{ fontSize: "16px", marginTop: "15px" }}>
                      Price of one unit of the NFT
                    </Typography>
                    <Input
                      name="price"
                      type="number"
                      required
                      min={1}
                      value={formData.price}
                      onChange={(e) => {
                        setFormData({ ...formData, price: e.target.value });
                      }}
                    />
                    <Typography sx={{ fontSize: "16px", marginTop: "15px" }}>
                      Quantity of units to sell
                    </Typography>
                    <Input
                      name="price"
                      type="number"
                      required
                      min={1}
                      max={nft.available_units}
                      value={formData.quantity}
                      onChange={(e) => {
                        // if (e.target.valueAsNumber > +balance) {
                        //   // console.log({
                        //   //   first: e.target.valueAsNumber,
                        //   //   q: formData.quantity,
                        //   // });
                        //   setFormData({
                        //     ...formData,
                        //     quantity: +balance,
                        //   });
                        //   return;
                        // }
                        // setFormData({
                        //   ...formData,
                        //   quantity: e.target.valueAsNumber,
                        // });
                        setFormData({
                          ...formData,
                          quantity: parseInt(e.target.value),
                        });
                      }}
                    />
                  </FormGroup>
                  <div className="d-flex justify-content-between">
                    <Button
                      className={`info-btn ${
                        loading === true ? "active-buy-btn" : ""
                      }`}
                      onClick={() => putOnSale(nft)}
                      variant="contained"
                    >
                      Sell
                    </Button>
                  </div>
                </div>
              )}
            </div>
          )}

        {["Primary Market", "Secondary Market"].includes(nft.state) && (
          <div className="d-flex justify-content-between">
            {componentState == "halted" && (
              <Button
                className="buy-btn mt-1"
                onClick={haltNFT}
                variant="contained"
              >
                Restart Sale
              </Button>
            )}
            {componentState == "active" && (
              <Button
                className="buy-btn mt-1"
                onClick={haltNFT}
                variant="contained"
              >
                Stop Sale
              </Button>
            )}
            <Button
            data-disabled={componentState == "halted"}
            // disabled={componentState == "halted"}
            
         
            title={componentState == "halted"?"Contract Halted":"Delete contract"}
              className="buy-btn mt-1"
              onClick={stopNFT}
              variant="contained"
            >
              Delete Contract
            </Button>
          </div>
        )}
      </Paper>
    </Grid>
  );
};

export default MyNFTItem;
