import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";
import { useAtom } from "jotai";
import { Input, Label, FormGroup } from "reactstrap";
import { Paper, Typography, Grid, Button } from "@mui/material";
import { SecondaryBuy } from "../../utils/functions/interact";
import { accountsAtom, walletAtom } from "../../utils/wallet";
import { API_URL, CONFIG } from "../../utils";
import algo from "../../assets/algo.png";
import "../PrimaryItem/primary_item.css";
import { networkConfig } from "../../config/network";
import { contract_views } from "../../utils/functions/contract_views";
import Swal from "sweetalert2";
import { connectWallet } from "../Navbar/utils";

const SecondaryItem = ({ nft, get_nft }) => {
  const navigate = useNavigate();
  const [accounts, setAccounts] = useAtom(accountsAtom);
  const [wallet, setWallet] = useAtom(walletAtom);
  const [toggleForm, setToggleForm] = useState(false);
  const [buyClicked, setBuyClicked] = useState(false);
  const [formData, setFormData] = useState({
    unit: "",
    total_price: 0,
  });
  const [info, setInfo] = useState({
    decimals: 0,
    name: "",
    price: 0,
    symbol: "",
    token: "",
    totalSupply: 0,
    bal: 0,
  });
  // useEffect(() => {
  //   contract_views({
  //     info: nft.contract_id,
  //     first: false,
  //   }).then(async (res) => {
  //     const bal = await res.balance();
  //     const meta = await res.meta();
  //     const _meta = meta[1];
  //     setInfo({
  //       ...info,
  //       ..._meta,
  //       bal: bal[1]?.[0],
  //     });
  //   });
  // }, []);

  let grand_id = nft.id;
  if (nft.grand_parent) {
    grand_id = nft.grand_parent;
  }

  const getExtension = (filename) => {
    return filename?.split(".").pop();
  };
  const verifyWallet = () => {
    Swal.fire({
      title: "Missing Wallet!",
      text: `Please connect your wallet and try again`,
      icon: "error",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const connect = await connectWallet("pera");
        setWallet(connect.wallet);
        setAccounts(connect.accounts);
        Swal.fire({
          title: "Connected wallet!",
          icon: "success",
          timer: 1200,
        });
      } else if (result.isDenied) {
        Swal.fire("Not connected", "", "info");
      }
    });

    // throw Error("No wallet connected");
  };
  const buyFromSecondary = (nft) => {
    if (!wallet?.networkAccount?.addr) {
      return verifyWallet();
    }
    setBuyClicked(true);
    if (
      localStorage.getItem("role")?.includes("Approved") &&
      localStorage.getItem("org_id") != "-1"
    ) {
      if (Object.keys(wallet).length === 0) {
        swal({
          title: "Missing Wallet!",
          text: `Please connect your wallet and try again`,
          icon: "error",
          button: false,
          timer: 1200,
        });
        setBuyClicked(false);
        return;
      }
      if (
        formData.unit?.includes(".") ||
        formData.unit > nft.total ||
        formData.unit < 1
      ) {
         swal({
          title: "Invalid Input!",
          text: `Unit should be a positive integer and not more than the available.`,
          icon: "error",
          button: false,
          timer: 1200,
        });
        return setBuyClicked(false);
      }
      const user = localStorage.getItem("user_id");

      const param = {
        wallet: wallet,
        amountToBuy: formData.unit,
        info: nft?.detail?.contract_id,
        tok: nft?.asset_id,
      };
      SecondaryBuy(param).then((response) => {
        
        if (response.includes("Successfully bought")) {
          const object = {
            user: user,
            state: "Secondary Market",
            unit: formData.unit,
            total_price: formData.total_price,
            address: localStorage.getItem("wallet-address")??wallet?.networkAccount?.addr,
            detail_id: nft?.detail?.id,
          };
          const body = JSON.stringify(object);
          axios
            .patch(`${API_URL}/nfts/${nft.id}/buy-in-secondary/`, body, CONFIG)
            .then((res) => {
              if (res.status === 200) {
                swal({
                  title: "Success!",
                  text: `Successfully bought  ${formData.unit} tokens`,
                  icon: "success",
                  button: false,
                  timer: 1200,
                });
                get_nft();
              }
            })
            .catch((error) => {
              console.log(error.message);
              swal({
                title: "Failed!",
                text: `Failed  trying to purchase`,
                icon: "error",
                button: false,
                timer: 1200,
              });
            });
        } else {
          swal({
            title: "Failed!",
            text: `Failed  trying to purchase`,
            icon: "error",
            button: false,
            timer: 1200,
          });
        }
        setBuyClicked(false);
      }).catch((error) => {
        if(error?.message?.toString()?.toLowerCase()?.includes("tried to spend")){
          Swal.fire({
            title: "Failed!",
            text: `Insufficient funds in your wallet`,
            icon: "error",
            button: false,
            timer: 1200,
          })
        }
        else   if (
          error?.message
            ?.toString()
            ?.includes("Request Rejected: The User has rejected the request.")
        ) {
          Swal.fire({
            title: "Failed!",
            text: `The User has rejected the request.`,
            icon: "error",
            button: false,
          });
        }
        else   if (
          (
            error?.message
              ?.toString()
              ?.includes("balance is less.")
          )  ) {
          Swal.fire({
            title: "Failed!",
            text: `Balance is less than the requested amount.`,
            icon: "error",
            button: false,
          });
        }
        else   if (
          (
            error?.message
              ?.toString()
              ?.includes("You are trying to buy more than what is available")
          )  ) {
          Swal.fire({
            title: "Failed!",
            text: `You are trying to buy more than what is available.`,
            icon: "error",
            button: false,
          });
        }
       else{
        Swal.fire({
          title: "Failed!",
          text: `Failed to purchase`,
          icon: "error",
          button: false,
          timer: 1200,
        })
       }
      });
    } else {
      swal({
        title: "Unauthorized User!",
        text: `You should be a registered organisation to use this service.`,
        icon: "error",
        button: false,
        timer: 1200,
      });
      setBuyClicked(false);
      return;
    }
    setBuyClicked(false);
  };

  return (
    <Grid item xs={12} sm={6} md={6} lg={4} key={nft.id}>
      {nft.tx_id.length ? (
        <Paper
          className="detail-page-link ml-0"
          sx={{ p: 2 }}
          style={{ boxShadow: "none", border: "1px solid #d9dbdd" }}
        >
          <span onClick={() => navigate(`/market-history/${grand_id}/`)}>
            {getExtension(nft.file)?.toLowerCase() === "pdf" && (
              <iframe src={nft.asset_url} width="100%" height="220px"></iframe>
            )}
            {getExtension(nft.file)?.toLowerCase() !== "pdf" && (
              <img
                src={nft.asset_url}
                alt="Thumbnail"
                height={220}
                width="100%"
                className="my-1"
              />
            )}
          </span>
          {nft.state == "Secondary Market" && (
            <div className="mt-1">
              <Typography sx={{ marginTop: "8px" }}>
                DCarbonX ID: <b>{nft.nft_id}</b>
              </Typography>
              <Typography sx={{ fontSize: "16px", marginTop: "10px" }}>
                Seller of Fractionalised NFT units:{" "}
                <strong>{nft.org_name}</strong>
              </Typography>
              <Typography sx={{ fontSize: "16px" }}>
                Total Supply of Units: <strong>{nft.detail?.total}</strong>
              </Typography>
              {/* {nft.total > nft.occupied && ( */}
              <Typography sx={{ fontSize: "16px" }}>
                Available Units: <strong>{nft?.detail?.amount}</strong>
              </Typography>
              {/* )} */}
              {/* <Typography sx={{ fontSize: '16px', marginTop: '0px' }}>
                                    1 Unit: <strong>{(nft.carbon_credits / nft.total) } {nft.unit_name}</strong>
                                </Typography> */}
              <Typography sx={{ fontSize: "16px", marginTop: "0px" }}>
                Units Sold:{" "}
                <strong> {nft?.detail?.total - nft?.detail?.amount}</strong>
              </Typography>
              <Typography sx={{ fontSize: "16px", marginTop: "5px" }}>
                Price/ Unit:{" "}
                <img className="m-1" src={algo} alt="thumb" width={20} />
                <strong>{nft.detail?.price / 1000000}</strong>
              </Typography>
              <Typography sx={{ fontSize: "16px", marginTop: "5px" }}>
                Holders of Sold Units:
              </Typography>
              <ul style={{ marginTop: "-10px", marginBottom: "5px" }}>
                {nft.holders.map((holder) => {
                  return (
                    <li
                      key={holder}
                      style={{ listStyleType: "none", fontSize: "14px" }}
                    >
                      <strong>{holder}</strong>
                    </li>
                  );
                })}
              </ul>

              <Typography
                sx={{ fontSize: "16px", marginTop: "10px" }}
                onClick={() =>
                  window.open(
                    `${networkConfig?.explorer?.asset}${nft.asset_id}`,
                    "_blank"
                  )
                }
              >
                Details of all transactions: <br />
                <span style={{ wordBreak: "break-all", color: "#335383FF" }}>
                  {networkConfig?.explorer?.asset}
                  {nft.asset_id}
                </span>
              </Typography>
              <div className="d-flex justify-content-between align-items-top mb-3">
                {nft?.detail?.amount == 0 && (
                  <Typography className="sold-out">SOLD OUT</Typography>
                )}
                {nft.detail?.detail_state === "halted" && (
                  <Typography className="sold-out">inactive</Typography>
                )}
                <div className="info-btn-wrapper">
                  <Button
                    variant="contained"
                    className="info-btn"
                    onClick={() => navigate(`/market-history/${grand_id}/`)}
                  >
                    More Info
                  </Button>
                </div>
              </div>
              {nft?.detail?.amount > 0 &&
                nft.detail?.detail_state === "active" && (
                  <div>
                    <div className="d-flex justify-content-between align-items-top">
                      <div>
                        <FormGroup>
                          <Typography
                            sx={{
                              fontSize: "16px",
                              marginTop: "5px",
                              fontWeight: 500,
                            }}
                          >
                            Carbon Credits
                          </Typography>
                          <Input
                            name="unit"
                            type="number"
                            required
                            min={1}
                            max={nft.occupied - nft.sold}
                            value={formData.unit}
                            placeholder="# units"
                            onChange={(e) => {
                              setFormData({
                                ...formData,
                                unit: e.target.value,
                                total_price: e.target.value * nft.price,
                              });
                            }}
                          />
                          <small style={{ fontSize: "12px" }}>
                            Number of units you want to buy
                          </small>
                        </FormGroup>
                      </div>

                      <div className="price-wrapper">
                        <Typography
                          sx={{
                            fontSize: "16px",
                            marginTop: "5px",
                            fontWeight: 500,
                          }}
                        >
                          Price
                        </Typography>
                        <span
                          sx={{
                            color: "success",
                            width: "100%",
                            marginTop: "10px",
                            fontWeight: "600",
                          }}
                        >
                          <img
                            className="m-1"
                            src={algo}
                            alt="thumb"
                            width={25}
                          />
                          {(formData.unit * nft?.detail?.price) / 1000000}{" "}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        className={`buy-btn ${
                          buyClicked === true ? "active-buy-btn" : ""
                        }`}
                        onClick={() => buyFromSecondary(nft)}
                        color="success"
                        variant="contained"
                      >
                        Buy
                      </Button>
                    </div>
                  </div>
                )}
            </div>
          )}
        </Paper>
      ) : (
        ""
      )}
    </Grid>
  );
};

export default SecondaryItem;
